@media (min-width: 1000px) { 

  @keyframes fade-in-slide-one {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fade-in-slide-two {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }


  #about {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
}

  img {
    border-radius: 20px;
}

.about-mother-container {
  position: relative;
  top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  max-width: 1000px;
  height: 100vh;
}

.container-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  min-height: 800px;
  gap: 50px;

}

.profile-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  grid-row: 1 / 2;
  grid-column: 2 /3 ;
  animation: fade-in-slide-two 0.5s ease-in-out;
}

.image {
  border-radius: 10px;
  height: 450px;
  width: 450px;

}

.about-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 60px;
  animation: fade-in-slide-one 0.5s ease-in-out;
  }

.hide-reading {
  min-height: 100px;
  visibility: hidden;
  display: block;
}

  .brief-history-section {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  
  .brief-history-section p {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .history-section-logos {
    margin-bottom: 20px;
    margin-top: 30px;
    height: 80px;
    width: 80px;
    border-radius: 10px;
  }
  
  .arrow {
    height: 120px;
    width: 3px;
    background-color: black;
  }

  .tech-skills-section {
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
  }
  
  .tech-skills-grid {
    height: 300px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr ;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }
  
  .tech-image {
   
    height: 100px;
    width: 100px;
    background-color: brown;
    border-radius: 10px;
  }

  .about-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-top: 100px;
  }

  .vanity-section {
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
  }
  
  .vanity-grid {
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    height: 500px;
    width: 900px;
    grid-template-rows: 1fr 1fr   ;
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 20px;
  }
  
  .vanity-logos {
    margin-bottom: 20px;
    height: 70px;
    width: 70px;
  }
  
  .vanity-images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    width: 100%;
    border-radius: 10px;
  
  }

  .books-read-section {
    width: 100%;
    height: 1000px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
  }
  
  .books-grid {
    display: grid;
    height: 800px;
    width: 100%;
    grid-template-rows: 1fr 1fr  ;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    gap: 20px;
  }
  
  .book-container {
  
    height: 350px;
    width: 250px;
  }

  .books-reading-section {
    height: 700px;
    min-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 150px;
  }
  
  .book-container-lonely {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    height: 600px;
    width: 100%;
  }
  
  .books-lonely{
  
    height: 520px;
    width: 400px;
  }
  
  .back-to-top {
    height: 200px;
    width: 100%;
    min-height: 50px;
    color: rgb(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }












}








/* tablet */
@media (min-width: 600px) and (max-width: 1000px) {

  @keyframes fade-in-slide {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  #about {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

  img {
    border-radius: 20px;
}
  .about-mother-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    min-width: 600px;
    max-width: 900px;
    height: 100%;
    width: 100%;
    padding: 60px;
    padding-bottom: 0px;
}

.container-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

}

.profile-pic {
  max-width: 600px;
  border-radius: 10px;
  max-height: 600px;
  grid-row: 1 / 2;
  grid-column: 2 /3 ;
  animation: fade-in-slide 0.5s ease-in-out;
}

.image {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.about-header {
  max-width: 600px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 60px;
  animation: fade-in-slide 0.6s ease-in-out;
  }

  /* hidden element: */

.visible-element {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction:column;
  justify-content: space-between;
}

.brief-history-section {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

}

.brief-history-section p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.history-section-logos {
  margin-bottom: 20px;
  margin-top: 30px;
  height: 80px;
  width: 80px;
  border-radius: 10px;
}

.arrow {
  height: 120px;
  width: 3px;
  background-color: black;
}

.tech-skills-section {
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 100px;
}

.tech-skills-grid {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.tech-image {
 
  height: 100px;
  width: 100px;
  background-color: brown;
  border-radius: 10px;
}

.vanity-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 100px;
}

.vanity-grid {
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  height: 700px;
  width: 500px;
  grid-template-rows: 1fr 1fr 1fr ;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.vanity-logos {
  margin-bottom: 15px;
  height: 70px;
  width: 70px;
}

.vanity-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  width: 100%;
  border-radius: 10px;

}

.books-read-section {
  width: 100%;
  min-height: 1800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;

}

.books-grid {

  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 1fr 1fr 1fr ;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 30px;
}

.books {
  height: 100%;
  width: 100%;
}

.books-reading-section {
  min-height: 700px;
  min-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 100px;
}

.book-container-lonely {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: 600px;
  width: 100%;
}

.books-lonely{

  height: 520px;
  width: 400px;
}

.back-to-top {
  height: 200px;
  width: 100%;
  min-height: 50px;
  color: rgb(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

  .about-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    margin-top: 100px;
  }














}




















/* mobile */
@media (min-width: 300px) and (max-width: 600px) {

  @keyframes fade-in-slide-about {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }



  #about {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  .about-mother-container {
    display: flex;
    width: 100vw;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

}

  
  .container-top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0px;
  height: 100%;
  min-height: 500px;
  max-height: 700px;

}

.intro {
  height: 100%;
}

.profile-pic {
  animation: fade-in-slide-about 0.5s ease-in-out;
  border-radius: 10px;
  min-height: 300px;
  max-height: 400px;

}

.image {
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.about-header {
  animation: fade-in-slide-about 0.6s ease-in-out;
  min-height: 300px;
  max-width: 300px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 12vw;
  

  }

/* hidden element: */
.brief-history-section {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

}

.brief-history-section p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.history-section-logos {
  margin-bottom: 20px;
  margin-top: 30px;
  height: 80px;
  width: 80px;
  border-radius: 10px;
}

.arrow {
  height: 120px;
  width: 3px;
  background-color: black;
}
  
.tech-skills-section {

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.tech-skills-grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.tech-image {
 
  height: 80px;
  width: 80px;
  background-color: brown;
  border-radius: 10px;
}

.about-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 50px;
}

.vanity-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.vanity-grid {
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  height: 500px;
  width: 100%;
  grid-template-rows: 1fr 1fr 1fr ;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.vanity-logos {
  margin-bottom: 10px;
  height: 60px;
  width: 60px;
}

.vanity-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  width: 100%;
  border-radius: 10px;

}

.books-read-section {
  width: 100%;
  min-height: 900px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}

.books-grid {
  padding-left: 20px;
  padding-right: 20px;
  display: grid;
  height: 100%;
  width: 100%;
  max-height: 800px;
  grid-template-rows: repeat(3, minmax(150px 200px) ) ;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 20px;
}

.books-reading-section {
  min-height: 600px;
  min-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.books {
  height: 100%;
  width: 100%;
}

.book-container-lonely {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: 400px;
  width: 100%;
}

.books-lonely{
 
  height: 320px;
  width: 200px;
}
}

 
