#twitter-icon {

    transition: transform 0.1s ease-in-out;
}

#twitter-icon:hover {
    transform: scale(1.4);
}

.nav-bar li {
    transition: transform 0.1s ease-in-out;
}

.nav-bar li:hover {
    transform: scale(1.2); 
 }






@media (min-width: 1000px) { 



    
    #twitter-icon {
        max-width: 50px;
        transition: transform 0.1s ease-in-out;
    }

    #twitter-icon:hover {
        transform: scale(1.4);
    }


    nav {
        position: fixed;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        width: 100vw;
        background-color: black;
        padding: 20px;
        padding-right: 50px;
    }

    .nav-bar {
        height: 100%;
        font-family: Lato, sans-serif;
        font-weight: 400;
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding: 20;
        color: white;
        width: 100%;
        min-width: 300px;
        max-width: 400px;

    }
    .nav-bar li {
        transition: transform 0.1s ease-in-out;
    }

    .nav-bar li:hover {
        transform: scale(1.2); 
     }

   

}







@media (min-width: 600px) and (max-width: 1000px) {

    #twitter-icon {
        max-width: 50px;
    }



    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        width: 100%;
        background-color: black;
        padding: 20px;
    }
    

    .nav-bar {
        height: 100%;
        font-family: Lato, sans-serif;
        font-weight: 400;
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding: 20;
        color: white;
        width: 100%;
        min-width: 300px;
        max-width: 400px;
    }

    

}

@media (min-width: 300px) and (max-width: 600px) {


    #twitter-icon {
        max-width: 50px;
        margin-left: 10px;
    }
    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        width: 100%;
        background-color: black;
        padding: 20px;
    }
    
    .nav-bar {
        height: 100%;
        font-family: Lato, sans-serif;
        font-weight: 400;
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding: 20;
        color: white;
        width: 100%;
        min-width: 250px;
        max-width: 300px;
    }
}

