.card {transition: transform 0.2s ease-in-out;}
.card:hover {
  transform: scale(1.05);

}


.contact-me-button {
  transition: transform 0.1s ease-in-out;
  }
  
  .contact-me-button:hover {
    transform: scale(1.1);
  
  }

@media (min-width: 1000px) { 


  @keyframes fade-in-slide-Y {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fade-in-slide-one {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fade-in-slide-two {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  #root {
    width: 100vw;
    height: 100vh;
  }


  .app-projects {
    position: relative;
    top: 70px;
    width: 100%;
    max-width: 1200px;
    min-height: 3300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  }

  .introduction-container {
    min-height: 600px;
    width: 100%;
    max-width: 1200px;
    display: grid;
    justify-content: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    gap: 30px;

  }


.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  
}

.introduction-title {
  text-align: center;
  font-family: "Butler";
  font-weight: 700;
  font-size: min(5vw, 80px);
  animation: fade-in-slide-one 0.5s ease-in-out;
  
}

.project-sub-title {
  text-align: center;
  font-size: min(1.5vw, 20px);
  animation: fade-in-slide-one 0.5s ease-in-out;
}

.intro-main-img {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 600px;
  width: 100%;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  animation: fade-in-slide-two 0.5s ease-in-out;
}

.projects-button-container {
  position: relative;
  top: 420px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  animation: fade-in-slide-one 0.5s ease-in-out;

}


.contact-me-button {
font-family: Lato;
font-size: 20px;
font-weight: 600;
height: 60px;
width: 120px;
border-radius: 15px;
color: white;
background-color: black;
border: 0;
transition: transform 0.2s ease-in-out;
cursor: pointer;
}

.contact-me-button:hover {
  transform: scale(1.1);

}

.cards {
  display: grid;
  max-height: 100%;
  width: 100%;
  max-width: 1200px;
  grid-template-columns: repeat(2, minmax(400px, 700px));
  grid-template-rows: repeat(3, minmax(600px, 700px)); 
  gap: 50px;
}


.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-family: 'Butler';
  font-weight: 700;
  height: 100%;
  border-radius: 15px;
  width: 100%;
  padding: 60px;
  box-shadow: 3px 3px 10px;
  color:rgb(107, 106, 106);
  transition: transform 0.2s ease-in-out;
  animation: fade-in-slide-Y 0.5s ease-in-out;
  
}

.card:hover {
  transform: scale(1.05);

}

.card-title-container {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.project-title {
  text-align: left;
  font-size: 40px;
  color: black;
}




.card-subtitle {
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0, 0.5);
  margin-bottom: 8px;
}











}










/* Tablet */
@media (min-width: 600px) and (max-width: 1000px) { 

  @keyframes fade-in-slide {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 700px;
  }



  #root {
    display: flex;
    flex-direction: column;
    align-items:center;
    width: 100%;
    height: 100%;
  }
  
  .app-projects {
    padding: 50px;
    width: 100%;
    max-width: 900px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }


  .introduction-container {
    min-height: 950px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px;
  }


.introduction {
  width: 100%;
  margin-top: 20px;
}

.introduction-title {
  font-family: "Butler";
  font-weight: 700;
  font-size: 9vh;
  animation: fade-in-slide 0.5s ease-in-out;
}

.project-sub-title {
  font-size: 3vw;
  line-height: 3.5vh;
  animation: fade-in-slide 0.5s ease-in-out;
}

.intro-main-img {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  animation: fade-in-slide 0.6s ease-in-out;

}

.projects-button-container {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in-slide 0.6s ease-in-out;
}


.contact-me-button {
font-family: Lato;
font-size: 20px;
font-weight: 600;
height: 60px;
width: 120px;
border-radius: 15px;
color: white;
background-color: black;
border: 0;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-family: 'Butler';
  font-weight: 700;
  height: 700px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 60px;
  padding: 60px;
  box-shadow: 3px 3px 10px;
  color:rgb(107, 106, 106);
  animation: fade-in-slide 0.6s ease-in-out;
}

.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.card-title-container {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.project-title {
  font-size: 50px;
  color: black;
}


.card-subtitle {
  width: 100%;
  text-align: left;
  font-size: 25px;
  font-family: 'Lato', sans-serif;
  color: rgb(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.project-main-imgs {
  height: 400px;
  width: 400px;
}










}











/* mobile */
@media (min-width: 280px) and (max-width: 600px) { 

  @keyframes fade-in-slide-projects {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }




  .app-projects {
    padding: 20px;
    width: 100%;
    height:fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

.introduction-container {
  max-height: 800px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
}

.introduction {
   
    width: 100%;
    margin-top: 20px;
  }
  
.introduction-title {
  animation: fade-in-slide-projects 0.5s ease-in-out;
    font-family: "Butler";
    font-weight: 700;
    font-size: 14vw
  }

.project-sub-title {
  animation: fade-in-slide-projects 0.5s ease-in-out;
  font-size: 4vw;
  line-height: 5.5vw;
    max-width: 500px;
  }

.intro-main-img {
  animation: fade-in-slide 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 500px;
    max-width: 600px;
    margin-top: 30px;
    margin-bottom: 30px;

  }

.projects-button-container {
  animation: fade-in-slide 0.5s ease-in-out;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


.contact-me-button {
  font-family: Lato;
  font-weight: 600;
  height: 50px;
  width: 100px;
  border-radius: 15px;
  color: white;
  background-color: black;
  border: 0;
}
  
 .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-family: 'Butler';
    font-weight: 700;
    height: 500px;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 60px;
    padding: 40px;
    box-shadow: 3px 3px 10px;
    color:rgb(107, 106, 106);
    animation: fade-in-slide 0.6s ease-in-out;
  }

  .cards {
    width: 100%;
    max-width: 500px;
  }
  
.card-title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }


.card-subtitle {
    width: 100%;
    text-align: left;
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    color: rgb(0, 0, 0, 0.5);
    margin-bottom: 8px;
  }

.project-main-imgs {
    height: 300px;
    width: 300px;
  }

.project-title {
    font-size: 35px;
    color: black;
  }

.maintenance {
    height: 100px;
    width: 100%;
    font-size: 40px;
  }

}