/* Laptop */
@media (min-width: 1000px) { 

  @keyframes fade-in-slide-one {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fade-in-slide-two {
    from {
      opacity: 0;
      transform: translateX(100px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }


  .contact-main-img {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    min-width: 200px;
    max-height: 200px;
    animation: fade-in-slide-one 0.5s ease-in-out;
  }

  .mother-container-about {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

  }

  .title {
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .contact-title {
    font-size: min(3.5vw, 60px);
    text-align: center;
    animation: fade-in-slide-one 0.5s ease-in-out;
  }

  .contact-sub-title {
    margin-top: 40px;
    font-size: min(2vw, 25px);
    text-align: center;
    color: rgb(0, 0, 0,0.5);
    animation: fade-in-slide-one 0.5s ease-in-out;

  }

  .background {
    padding: 60px;
    width: 100%;
    background-color: bisque;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 300px;
    width: 100%;
    max-width: 600px;
    gap: 20px;
    animation: fade-in-slide-two 0.5s ease-in-out;
  }

  #email {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    transition: transform 0.2s ease-in-out;

  }

 #email:hover {
    transform: scale(1.1);
}

  #phone {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    transition: transform 0.2s ease-in-out;

  }

  #github {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    transition: transform 0.2s ease-in-out;
  }

  #github:hover {
    transform: scale(1.1);
}

  #linked-in {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    transition: transform 0.2s ease-in-out;
  }

  #linked-in:hover {
    transform: scale(1.1);
}


  
  .contact-info-container {
    padding-left: 20px;
    padding-right: 20px;
      height: 150px;
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 100%;
      gap: 20px;
  }
  
  .card-small {
    display: flex;
    align-items: center;
      font-family: 'Lato';
      font-weight: 700;
      height: 100%;
      border-radius: 5px;
      width: 100%;
      padding: 20px;
      background-color: white;
      border-radius: 10px;
    }
  
    .contact-card-subtitle {
      font-size: 15px;
      font-family: 'Lato', sans-serif;
      color: rgb(0, 0, 0, 0.5);
      margin-bottom: 8px;
    }
  
    .contact-info-container-two {
      margin-bottom: 30px;
      height: 50px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between
    }
    
  
   

    .email-logo {
      height: 30px;
      width: 30px;
      background-color: black;
      border-radius: 5px;
      margin-right: 10px;
    }
  
    .phone-logo {
      height: 30px;
      width: 30px;
      background-color: black;
      border-radius: 5px;
  
      margin-right: 10px;
    }
  

}











/* Tablet */
@media (min-width: 600px) and (max-width: 1000px) {

  @keyframes fade-in-slide {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fade-in-slide-Y {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .contact-main-img {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    min-width: 200px;
    max-height: 200px;
    animation: fade-in-slide 0.6s ease-in-out;
  }

  .mother-container-about {
    /* padding: 40px; */
    height: 100vh;
    display: flex;
    flex-direction: column;

  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 60px;
    height: 50%;
  }

  .contact-title {
    font-size: 7vw;
    text-align: center;
    animation: fade-in-slide 0.5s ease-in-out;
  }

  .contact-sub-title {
    margin-top: 30px;
    font-size: 3.5vw;
    text-align: center;
    color: rgb(0, 0, 0,0.5);
    animation: fade-in-slide 0.7s ease-in-out;

  }

  .background {
    padding: 120px;
    width: 100%;
    background-color: bisque;
    height: 50%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 300px;
    width: 100%;
    max-width: 600px;
    gap: 20px;
    animation: fade-in-slide-Y 0.7s ease-in-out;
  }

  #email {
    grid-row: 1 / 2;
    grid-column: 1 / 3;

  }

  #phone {
    grid-row: 2 / 3;
    grid-column: 1 / 3;

  }

  #github {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }

  #linked-in {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
  }
  
  .contact-info-container {
    padding-left: 20px;
    padding-right: 20px;
      height: 150px;
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 100%;
      gap: 20px;
  }
  
  .card-small {
    display: flex;
    align-items: center;
      font-family: 'Lato';
      font-weight: 700;
      height: 100%;
      border-radius: 5px;
      width: 100%;
      padding: 20px;
      background-color: white;
      border-radius: 10px;
    }
  
    .contact-card-subtitle {
      font-size: 15px;
      font-family: 'Lato', sans-serif;
      color: rgb(0, 0, 0, 0.5);
      margin-bottom: 8px;
    }
  
    .contact-info-container-two {
      margin-bottom: 30px;
      height: 50px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between
    }
    
  
   

    .email-logo {
      height: 30px;
      width: 30px;
      background-color: black;
      border-radius: 5px;
      margin-right: 10px;
    }
  
    .phone-logo {
      height: 30px;
      width: 30px;
      background-color: black;
      border-radius: 5px;
  
      margin-right: 10px;
    }
  












}









/* Mobile */
@media (min-width: 300px) and (max-width: 600px) {

  @keyframes fade-in-slide {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fade-in-slide-Y {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


  #root {
    height: 100vh;
  }

  img {
    border-radius: 10px;
  }

  .contact-main-img {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    min-width: 200px;
    max-height: 100px;
    animation: fade-in-slide 0.6s ease-in-out;
  }

.mother-container-about {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
  height: 100%;
}
.contact-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11vw;
  text-align: center;
  min-height: 120px;
  animation: fade-in-slide 0.5s ease-in-out;
}

.contact-sub-title {
  margin-top: 30px;
  font-size: 20px;
  text-align: center;
  color: rgb(0, 0, 0,0.5);
  animation: fade-in-slide 0.7s ease-in-out;

}


.card-small {
  display: flex;
  align-items: center;
    font-family: 'Lato';
    font-weight: 700;
    height: 100%;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
  }

  .contact-card-subtitle {
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    color: rgb(0, 0, 0, 0.5);
    margin-bottom: 8px;
  }


  .background {
    height: 100%;
    padding: 30px;
    width: 100vw;
    background-color: bisque;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 250px;
    width: 100%;
    gap: 20px;
    animation: fade-in-slide-Y 0.7s ease-in-out;
  }

  #email {
    grid-row: 1 / 2;
    grid-column: 1 / 3;

  }

  #phone {
    grid-row: 2 / 3;
    grid-column: 1 / 3;

  }

  #github {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  #linked-in {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
  }
  

  .email-logo {
    height: 30px;
    width: 30px;
    background-color: black;
    border-radius: 5px;
    margin-right: 10px;
  }

  .phone-logo {
    border-radius: 10px;
    height: 30px;
    width: 30px;
    background-color: black;
    border-radius: 5px;
    margin-right: 10px;
  }

}