*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0
}

body {
  height: 100vh;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

h1 {
  font-family: 'Butler';
}

.list {
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: black;
  list-style:disc;
  flex: 1;
  margin-bottom: 20px;
}

.list li {
  margin-bottom: 8px;
  font-weight: 400;
}

@media (min-width: 1000px) { 
  p {
    font-size: 20px;
  }

  li {
    font-size: 20px;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    align-items:center;
    height: 100vh;
    min-width: 1000px;
    width: 100vw;
  }
  
  .app {
    width: 100%;
    padding: 20px;
  }
  
  
  .weather-widget {
    padding: 0px;
  }
  
  nav {
  min-height: 70px;
  }
  
  .nav-bar {
    background-color: rgb(8, 8, 8);
  }
  
  .nav-bar li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
}







/* tablet */
@media (min-width: 600px) and (max-width: 1000px) {
  p {
    font-size: 20px;
  }

  li {
    font-size: 20px;
  }
  
 
  
  .app {
    width: 100%;
    padding: 20px;
  }
  
  
  .weather-widget {
    padding: 0px;
  }
  
  nav {
  min-height: 70px;
  }
  
  .nav-bar {
    background-color: rgb(8, 8, 8);
  }
  
  .nav-bar li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

}

/* mobile */
@media (min-width: 300px) and (max-width: 500px) {

  p {
    font-size: medium
  }
  
  #root {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items:center;
    height: 100vh;
  }
  
  .app {
    width: 100%;
    padding: 20px;
  }
  
  
  .weather-widget {
    padding: 0px;
  }
  
  nav {
  min-height: 70px;
  }
  
  .nav-bar {
    background-color: rgb(8, 8, 8);
  }
  
  .nav-bar li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
 }




