
/* desktop */
@media (min-width: 1000px) { 

    #hang-man {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
    }

    

    .game-logic {
        border-radius: 15px;
        background-color:rgb(196, 252, 255);
        padding: 30px;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .list-numbers {
        padding: 30px;
        display: flex;
        flex-direction: column;
        color: black;
        list-style:decimal;
        flex: 1;
        margin-bottom: 20px;
    }

    .list-numbers li {
        margin-bottom: 20px;
        font-weight: 400;
    }
    
    

    .section-titles {
        font-size: 40px;
        margin-bottom: 15px;
    }



    #hangman-development {

        margin-top: 150px;
        height: 700px;
        display: grid;
        grid-template-rows: 0.1fr 0.5fr ;
        grid-template-columns: 50% 50%;
        gap: 30px;
        align-items: flex-start;
        justify-content: center;

    }

    #hangman-challenges {
        min-height: 3500px;
        display: grid;
        grid-template-rows: 0.1fr 0.5fr 0.5fr 0.6fr 0.5fr 0.5fr ;
        grid-template-columns: 50% 50%;
        row-gap: 100px;
    }

    #hangman-image-challenges {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
    }


    .solution-two {
        grid-row: 5 / 6;
        grid-column: 2 / 3
    }

    #solution-img-one {
        grid-row: 4 / 5;
        grid-column: 1 / 3;
    }

    #solution-img-two{
        grid-row: 6 / 7;
        grid-column: 1 / 3;
    }

    .hangman-illustration-section {

        min-height: 1000px;
        margin-top: 150px;
        display: grid;
        grid-template-rows: 0.1fr 0.5fr;
        grid-template-columns: 1fr 1fr;
      gap: 50px;
    }

    #hangman-img {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }

    .solution-title {
        font-size: 25px;
    }











}








/* tablet */
@media (min-width: 600px) and (max-width: 1000px) {

    #hang-man {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }

    .hangman-illustration-section {

        margin-top: 100px;
        display: grid;
        grid-template-rows: 0.1fr 1fr;
        grid-template-columns: 50% 50%;
    }

    #hangman-img {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }

    .game-logic-section {
        border-radius: 15px;
        background-color:rgb(196, 252, 255);
        padding: 60px;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .list-numbers {
        padding: 30px;
        display: flex;
        flex-direction: column;
        color: black;
        list-style:decimal;
        flex: 1;
        margin-bottom: 20px;
    }

    .list-numbers li {
        margin-bottom: 30px;
        font-weight: 400;
    }
    
    

    .section-titles {
        font-size: 40px;
        margin-bottom: 15px;
    }

    #hangman-challenges {
        margin-top: 100px;
        min-height: 3000px;
        display: grid;
        row-gap: 60px;
        grid-template-rows: 0.1fr 0.5fr 0.5fr 0.6fr 0.5fr 0.5fr ;
        grid-template-columns: 50% 50%;
    }

    #hangman-image-challenges {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        margin-bottom: 0px;
    }


    #solution-img-one {
        margin-top: 0px;
        grid-row: 4 / 5;
        grid-column: 1 / 3;
        margin-bottom: 0px;

    }


    #solution-img-two {
        grid-row: 6 / 7;
        grid-column: 1 / 3; 

    }

    .back-to-top {
        font-size: 25px;
        margin-bottom: 20px;
    }


    .solution-list {
        height: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        color: black;
        list-style:disc;
        margin-bottom: 20px;
        font-weight: 400;
    }

    .solution-list li {
        margin-bottom: 20px;
    }

    .solution-title {
        font-size: 25px;
    }


}

/* mobile */
@media (min-width: 300px) and (max-width: 600px) {

    #hang-man {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }
 
    
    .game-logic-section {
        background-color:rgb(196, 252, 255);
        padding: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    
    .list-numbers {
        padding: 30px;
        display: flex;
        flex-direction: column;
        color: black;
        list-style:decimal;
        flex: 1;
        margin-bottom: 20px;
    }
    
    .list-numbers li {
        margin-bottom: 10px;
        font-weight: 400;
    }

    #hangman-challenges {
        padding: 20px;
        height: 2300px;
        display: grid;
        grid-template-rows: 0.05fr 0.1fr 0.1fr 0.05fr 0.1fr 0.5fr 0.1fr 0.1fr  ;
        grid-template-columns: 100%;
        margin-bottom: 30px;
    }

    #hangman-image-challenges {
        margin-bottom: 50px;
    }

    .solution-one {
        margin-bottom: 50px;
    }

    #solution-img-one {
        margin-bottom: 50px;
    }

    .solution-two {
        margin-bottom: 50px;
    }

    .hangman-illustration-section {

        margin-top: 30px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .solution-list li {
        margin-bottom: 20px;
    }





 }


