
@media (min-width: 1000px) { 

    #calculator {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
    }

    .IOS-functionality {
        margin-top: 150px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 50px;
        min-height:800px
    
    }

    #calculator-challenges {
        height: 1400px;
        margin-top: 100px;
        display: grid;
        grid-template-rows: 0.1fr 0.1fr 0.5fr ;
        grid-template-columns: 1fr 1fr;
        gap: 50px;

    }

    .video-calculator {
        background-color: black;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-height: 800px;
        width: 100%;
        grid-row: 1 / 2;
        grid-column: 2 / 3
    }

    #solution-img {
        grid-row: 3 / 4;
        grid-column: 1 / 3;
    }


    #ui-img-one {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }

    #ui-img-two {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
    }


}


@media (min-width: 600px) and (max-width: 1000px) {

    #calculator {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }



    .IOS-functionality {
        margin-top: 100px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 40px;
    }

    .video-calculator {
        background-color: black;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 720px;
        width: 100%;
        grid-row: 1 / 2;
        grid-column: 2 / 3
    }

    #ui-img-one {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }

    #ui-img-two {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
    }

    #calculator-challenges {
        gap: 20px;
    }

    #solution-img {
        grid-row: 3 / 4;
        grid-column: 1 / 3;
    }







}





/* mobile */
@media (min-width: 300px) and (max-width: 600px) {

    #calculator {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }


    video {
        border-radius: 15px;
    }

    .IOS-functionality {
        min-height: 950px;
        padding: 20px;
    
    }


    .video-calculator {
        margin-top: 50px;
        background-color: black;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 600px;
        width: 100%;
}

#calculator-challenges {
    height: 800px;
    padding: 20px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 0.1fr 0.1fr 0.05fr 0.5fr 0.5fr;
    gap: 10px;
    margin-bottom: 80px;
   
}

.solution-title {
    margin-top: 15px;
}

    

}

