
    .footer-button {
        font-size: 15px;
        border-radius: 5px;
        padding: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background-color:rgb(88, 17, 219);
        border: 0;
        color: white;
        margin-bottom: 15px;
        min-width: 100px;
        min-height: 40px;
        transition: transform 0.2s ease-in-out;

    }

    


.footer-button:hover {
  transform: scale(1.1);
  cursor: pointer;

}


@media (min-width: 1000px) { 

    #footer-button {
        display: flex;
        justify-content: center;
        width: 100%;
       }
    
        .footer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            min-height: 500px;
            background-color: black;
            padding: 60px;
            
        }
    
        .footer-title-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .get-in-touch {
            color: rgb(255, 255, 255, 0.5);
            margin-bottom: 10px;
        }
    
        .footer-title {
            text-align: center;
            font-size: 3.5vh;
            font-weight: 900;
            color: white;
            margin-bottom: 15px;
        }
    
        .footer-sub-title {
            max-width: 500px;
            font-size: 2vh;
            line-height: 3vh;
            font-weight: 400;
            text-align: center;
            color: white;
            max-width: 350px;

    
    
        }
    
        .footer-p {
            text-align: center;
            width: 100%;
            height: 20px;
            color: rgb(255, 255, 255, 0.5);
            font-size: 12px;
    
        }
    
        .footer-logos-container {
            display: flex;
            justify-content: space-evenly;
            width: 150px;
            margin-bottom: 15px;
        }
    
        .footer-logos {
          height: 35px;  
          width: 35px;
          margin-right: 10px;
        }
    
        .footer-button {
            font-size: 18px;
            border-radius: 5px;
            padding: 15px;
            padding-left: 20px;
            padding-right: 20px;
            background-color:rgb(88, 17, 219);
            border: 0;
            color: white;
            margin-bottom: 15px;
            min-width: 120px;
            min-height: 60px;
    
        }
    
        .footer-p-container {
            min-height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
        }
    


}


/* Tablet */
@media (min-width: 600px) and (max-width: 1000px) {

   #footer-button {
    display: flex;
    justify-content: center;
    width: 100%;
   }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 500px;
        background-color: black;
        padding: 50px;
        
    }

    .footer-title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .get-in-touch {
        color: rgb(255, 255, 255, 0.5);
        margin-bottom: 10px;
    }

    .footer-title {
        text-align: center;
        font-size: 3.5vh;
        font-weight: 900;
        color: white;
        margin-bottom: 15px;
    }

    .footer-sub-title {
        max-width: 500px;
        font-size: 2vh;
        line-height: 3vh;
        font-weight: 400;
        text-align: center;
        color: white;


    }

    .footer-p {
        text-align: center;
        width: 100%;
        height: 20px;
        color: rgb(255, 255, 255, 0.5);
        font-size: 12px;

    }

    .footer-logos-container {
        display: flex;
        justify-content: space-evenly;
        width: 150px;
        margin-bottom: 15px;
    }

    .footer-logos {
      height: 35px;  
      width: 35px;
      margin-right: 10px;
    }

    .footer-button {
        font-size: 18px;
        border-radius: 5px;
        padding: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background-color:rgb(88, 17, 219);
        border: 0;
        color: white;
        margin-bottom: 15px;
        min-width: 120px;
        min-height: 60px;

    }

    .footer-p-container {
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }



}



/* Mobile */
@media (min-width: 280px) and (max-width: 600px) {


    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 400px;
        background-color: black;
        padding: 30px;
        
    }

    .footer-title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .get-in-touch {
        color: rgb(255, 255, 255, 0.5);
        margin-bottom: 10px;
    }

    .footer-title {
        text-align: center;
        font-size: 3.5vh;
        font-weight: 900;
        color: white;
        margin-bottom: 15px;
    }

    .footer-sub-title {
        font-size: 2vh;
        line-height: 24px;
        font-weight: 400;
        text-align: center;
        color: white;


    }

    .footer-p {
        text-align: center;
        width: 100%;
        height: 20px;
        color: rgb(255, 255, 255, 0.5);
        font-size: 2.5vw;
        

    }

    .footer-logos-container {
        display: flex;
        justify-content: space-evenly;
        width: 150px;
        margin-bottom: 15px;
    }

    .footer-logos {
      height: 30px;  
      width: 30px;
      margin-right: 10px;
    }

    .footer-button {
        font-size: 15px;
        border-radius: 5px;
        padding: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background-color:rgb(88, 17, 219);
        border: 0;
        color: white;
        margin-bottom: 15px;
        min-width: 100px;
        min-height: 40px;
        transition: transform 0.2s ease-in-out;

    }

    


.footer-button:hover {
  transform: scale(1.1);

}

    .footer-p-container {
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }


 }

