/* Weather-widget sections */


/* laptop */
@media (min-width: 1000px) { 

    #weather-widget {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
    }
    
    .github-section {
        margin-top: 100px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 0.4fr 1fr;
        gap: 30px;
        padding-top: 0px;
        min-height: 700px;
          
    }

    #github-section-img {
        grid-column: 1 / 2;
    }

    .dynamic-rendering-section {
        margin-top: 100px;
        display: grid;
        grid-template-rows: 0.1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    
        padding-top: 0px;
        min-height: 600px
    }

    .render-section-title-container {
        grid-row: 1 / 2;
        grid-column: 1 / 2;

    }

    #day-img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }


    #night-img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        grid-row: 3 / 4;
        grid-column: 2 / 3;

    }




}



/* tablet */
@media (min-width: 600px) and (max-width: 1000px) { 


    #weather-widget {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }

    .github-section {
        margin-top: 100px;
        display: grid;
        grid-template-rows: 0.1fr 1fr;
        grid-template-columns: 50% 50%;
        padding: 50px
    }
    
    #github-section-img {
        grid-column: 1 / 2;
    }



    .dynamic-rendering-section {
        margin-top: 100px;
        display: grid;
        grid-template-rows: 0.1fr 1fr;
        grid-template-columns: 50% 50%;
        row-gap: 30px;
        padding: 50px
        
    }

    #day-img {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }

    #night-img {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
    }

    

}










/* mobile */
@media (min-width: 300px) and (max-width: 600px) { 

    #weather-widget {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }


    .dynamic-rendering-section {
        padding: 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    
    }
    
    .github-section {
        margin-top: 20px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
    }
}

