.twitter-container {
    transition: transform 0.2s ease-in-out;
}

.twitter-container:hover {
    transform: scale(1.2);
}



/* desktop */
@media (min-width: 1000px) {

    @keyframes fade-in-slide-one {
        from {
          opacity: 0;
          transform: translateX(-100px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    
      @keyframes fade-in-slide-two {
        from {
          opacity: 0;
          transform: translateX(100px);
        }
        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    
    #salford-red-devils {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
    }
    img {
        border-radius: 20px;
    }
    video {
        border-radius: 15px;
    }

   
   #devils-header {
        width: 100%;
        margin-top: 200px;
     
        margin-bottom: 60px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr;
        gap: 60px;
        align-items: center;
        justify-content: center;
    }

    .twitter-container {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        width: 100%;
        animation: fade-in-slide-one 0.5s ease-in-out;
    }

    

    #devils-development {
        min-height: 11500px;
        margin-top: 100px;
        display: grid;
        grid-template-rows: 0.1fr 0.1fr 0.1fr 0.3fr 0.3fr 0.5fr 0.1fr 0.3fr 0.5fr 0.1fr 0.5fr 0.1fr 0.5fr 0.1fr 0.5fr 0.1fr 0.5fr;
        grid-template-columns: 50% 50%;
        column-gap: 20px;
        row-gap: 100px
    }

    #story-board-last {
        grid-column: 1 / 3;
      }
    
        #trident-three {
            grid-column: 1 / 3;
        }
    
        #devil-illustrator {
            grid-column: 1 / 3;
        }
    
        #after-effects {
            grid-column: 1 / 3;
        }
    
        #premier-pro {
            grid-column: 1 / 3;
        }
    
        #sound-effects {
            grid-column: 1 / 3;
    
        }
    
        #puppet-pins {
            grid-column: 1 / 3;
        }
    
        #devils-challenges {
            height: 1400px;
                padding: 0px;
                display: grid;
                grid-template-rows: 0.1fr 0.1fr 0.5fr;
                grid-template-columns: 50% 50%;
                row-gap: 50px;
           
        }

        .main-video {
            
            min-height: 400px;
            max-width: 500px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: black;
            border-radius: 15px;
            animation: fade-in-slide-two 0.5s ease-in-out;
        }
    
        .devils-sub-title {
            text-align: center;
            font-size: 18px;
            animation: fade-in-slide-one 0.5s ease-in-out;
        }

    }

/* tablet */
@media (min-width: 600px) and (max-width: 1000px) {
    
    #salford-red-devils {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }

    #twitter-button {
        display: flex;
        justify-content: flex-start;
    }

    img {
        border-radius: 20px;
    }
    video {
        border-radius: 15px;
    }

    #devils-header {
        width: 100%; 
        height: 800px;  
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 100%;
        row-gap: 50px
    }

 
    
    .twitter-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        height: 50px;
        width: 100%;
        padding-top: 20px;
        animation: fade-in-slide-foundation 0.5s ease-in-out;
        
    }

    #devils-development {
        margin-top: 100px;
        display: grid;
        grid-template-rows: 0.1fr 0.5fr 0.1fr 0.5fr 0.5fr 0.5fr 0.1fr 0.5fr 0.5fr 0.1fr 0.5fr 0.5fr 0.5fr 0.1fr 0.5fr 0.1fr 0.5fr  ;
        grid-template-columns: 50% 50%;
        column-gap: 20px;
        row-gap: 100px
    }

  #story-board-last {
    grid-column: 1 / 3;
  }

    #trident-three {
        grid-column: 1 / 3;
    }

    #devil-illustrator {
        grid-column: 1 / 3;
    }

    #after-effects {
        grid-column: 1 / 3;
    }

    #premier-pro {
        grid-column: 1 / 3;
    }

    #sound-effects {
        grid-column: 1 / 3;

    }

    #puppet-pins {
        grid-column: 1 / 3;
    }

    #devils-challenges {
      
            padding: 0px;
            display: grid;
            grid-template-rows: 0.1fr 1fr;
            grid-template-columns: 50% 50%;
            row-gap: 50px;
       
    }

    .main-video {
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        border-radius: 15px;
        animation: fade-in-slide-foundation 0.5s ease-in-out;
    }

    .devils-sub-title {
        text-align: left;
        font-size: 18px;
        animation: fade-in-slide-foundation 0.5s ease-in-out;
    }


    








}


/* mobile */
@media (min-width: 300px) and (max-width: 600px) {


    #salford-red-devils {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
    }

    .main-video {
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 250px;
        min-width: 100%;
        width: 100%;
        animation: fade-in-slide-foundation 0.5s ease-in-out;
 
    }
    
    .twitter-container {
        height: 30px;
        width: 100%;
        padding-top: 20px;
        padding-left: 0px;
        animation: fade-in-slide-foundation 0.5s ease-in-out;
 
    }

    #devils-challenges {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr ;
        gap: 10px;
        margin-bottom: 80px;
    }

    #devils-development {
        margin-top: 100px;
        height: 7000px;
        display: grid;
        grid-template-rows: 0.05fr 0.1fr 0.05fr 0.1fr 0.05fr 0.1fr 0.1fr 0.5fr 0.5fr 0.1fr 0.5fr 0.05fr 0.1fr 0.1fr 0.5fr 0.5fr 0.05fr 0.05fr 0.5fr 0.05fr 0.35fr 0.5fr 0.05fr 0.05fr 0.5fr 0.05fr 0.05fr 0.5fr;
        grid-template-columns: 100%;
        column-gap: 20px;
        row-gap: 10px
    }
    .devils-sub-title {
        animation: fade-in-slide-foundation 0.5s ease-in-out;
    }

    .result {
        margin-top: 50px;
    }

    .devil-section-titles {
        font-size: 20px;
        margin-bottom: 10px;
    }
}